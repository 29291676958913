import React, { useEffect } from "react";
import Navbar from "../NavBar";
import image1 from "../../assets/images/GymManagement.png";
import image2 from "../../assets/images/GymManagement2.jpg";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
const GymManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar color={"black"} />
      <Helmet>
        <title>Gym Management Software</title>
        <meta
          name="description"
          content="Manage memberships and schedules with ease using our gym management system."
        />
        <meta
          name="keywords"
          content="gym management, fitness software, membership management, scheduling system"
        />
      </Helmet>
      <div className="container flex justify-center  flex-col gap-4 py-16 px-4 sm:px-6 lg:px-32">
        <div className="heading text-center">
          <h2 className="text-3xl lg:text-4xl font-bold text-Cdark">
            DigIT-Hack Gym Management Software
          </h2>
        </div>
        <h2 className="text-2xl font-semibold text-start">
          What is Gym Management Software:-
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Gym Management Software is CRM Software, which is used for Management
          of Gym Business related activities like Admissions, Renewals,
          Accounts, Due Reminder and all other activities that’s needed in gym
          management. DigIT-Hack Management Software is a Cloud based Gym
          Software that is used for manage gymnasium and fitness Club.
        </p>
        <img src={image1} alt="" className="mx-auto w-1/2" />
        <h2 className="text-2xl font-semibold text-start">Our Features:-</h2>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Admission and Renewal Management –
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Members management is a primary key for gym’s success. Best Gym
          Manager do it extremely well. It manages member personal details,
          attendances, membership plans and fee details.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Active and Inactive Membership –
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Gyms, Yoga Centers and Fitness Clubs can manage their membership
          plans, pricing, offers and active time period with this feature.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Enquiry & Follow Up Management –
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Its very important feature for gyms and yoga centers to keep a record
          of enquiries. It not only helps in getting new clients and but also
          help in client retention. DigIT-Hack Management Software comes with
          Follow Up feature to communicate with enquirer in super easy way.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Bills, Invoices & Sales Management –
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Best Gym Manager manages bills, invoices and sales. It provides
          customized billing formats. It also helps in analyzing daily, weekly,
          monthly or yearly sales.
        </p>
        <img src={image2} alt="" className="mx-auto w-1/2" />
        <h2 className="text-xl lg:text-2xl font-semibold">
          Staff Management System –
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Managing Staff is a pain in ass kind of task for Gyms and Fitness
          Clubs. DigIT-Hack Management Software helps in get rid of this pain.
          Clubs can manage Employee details, id proofs, attendances and payroll
          using this tool in a very easy manner.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
        Stocks And Inventory Management -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        Manage all the stocks of merchandise items like supplements, t-shirts, etc., and also any inventory kinds of stuff.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
        Diet Management And Class Scheduling -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        Schedule the classes and allots trainers as per the workout plans.
Provide diet plans directly through our website for different plans.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Reminders & Alerts –{" "}
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          It automatically sends SMS and Email alerts to members about fee
          payments and membership expirations. Gyms and Fitness clubs can also
          send SMS and Emails manually.
        </p>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        <b>*Biometric Device Integration</b> can be availed by us as per the requirement.
        </p>

        <h3 className="text-xl lg:text-2xl font-desc mb-2">
          Ready to Streamline Your Gym Operations? Transform your Gym's
          efficiency and customer satisfaction with our management software.
          <br />
          <span className="font-bold">
            Contact us to schedule a demo or discuss how our software can
            optimize your gym management!
          </span>
        </h3>
      </div>
      <Footer />
    </div>
  );
};

export default GymManagement;
