import React, { useEffect } from "react";
import Navbar from "../NavBar";
import image1 from "../../assets/images/SchoolManagement1.png";
import image2 from "../../assets/images/SchoolManagement2.jpg";
import image3 from "../../assets/images/SchoolManagement3.jpg";
import Footer from "../Footer";
import { Helmet } from "react-helmet";

const SchoolManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar color={"black"} />
      <Helmet>
        <title>School Management Software</title>
        <meta
          name="description"
          content="Revolutionize the way your institution operates with our school management software."
        />
        <meta
          name="keywords"
          content="school management, education software, student information system, school administration"
        />
      </Helmet>
      <div className="container flex justify-center  flex-col gap-4 py-16 px-4 sm:px-6 lg:px-32">
        <div className="heading text-center">
          <h2 className="text-3xl lg:text-4xl font-bold text-Cdark">
            DigIT-Hack School Management Software
          </h2>
        </div>
        <h2 className="text-2xl font-semibold text-start">
          Revolutionize the way your Institution Operates!
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          DigIT-Hack is designed and crafted by experienced educators and is
          known for its non-compromised support and transparent pricing. We meet
          all the needs to manage an Institution!
        </p>
        <img src={image1} alt="" className="mx-auto w-3/5" />
        <h2 className="text-xl lg:text-2xl font-semibold">
          Student Information Management:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            <b>Student Profiles:</b> Store student details like name, contact
            information, emergency contacts, etc.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          <b>Admissions:</b> Manage admission processes, including registration,
            documentation, and fee payments.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          <b>Attendance:</b> Track student attendance, generate reports, and notify
            parents about absences.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Academic Management:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          <b>Curriculum Planning:</b> Plan and manage the school curriculum,
            syllabus, and lesson schedules.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            <b>Grading and Assessments:</b> Record grades, conduct assessments, and
            generate report cards.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            <b>Timetable Management:</b> Create and manage class schedules and teacher
            timetables.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Teacher and Staff Management:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          <b>Teacher Profiles:</b> Store teacher details, qualifications, and contact
            information. Attendance and Leave Management: Track teacher
            attendance, manage leave requests, and schedules.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          <b>Performance Evaluation:</b> Conduct performance reviews and appraisals
            for staff.
          </li>
        </ul>
        <img src={image2} alt="" className="mx-auto w-2/4" />

        <h2 className="text-xl lg:text-2xl font-semibold">
          Fee and Financial Management:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          <b>Fee Collection And Management:</b> Can easily track and manage student fees, generate
detailed fee structures, and receive automated notifications for upcoming payments.
Real-time updates on fee statuses, enabling timely communication between parents,
students, and school administrators.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          <b>Financial Reports:</b> Generate financial reports, including income,
            expenses, and balance sheets.
          </li>
          
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Library Management:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          <b>Catalog and Inventory:</b> Maintain a catalog of books, manage inventory, and track lending.
Book Issuing and Returning: Handle book borrowing, returning, and reservation
processes.
          </li>
          
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Communication and Collaboration:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          <b>Parent-Teacher Communication:</b> Facilitate communication between
            teachers and parents through messaging or notifications.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          <b>Announcements and Notices:</b> Send out school-wide announcements, event
            notifications, and important notices.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          <b>Collaboration Tools:</b> Provide platforms for collaboration among
            teachers, students, and parents.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Extracurricular Activities and Events:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          <b>Event Management:</b> Plan, schedule, and manage school events,
            competitions, and extracurricular activities.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          <b>Sports Management:</b> Manage sports teams, schedules, and track
            achievements.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Transportation Management:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          <b>Route Planning:</b> Manage bus routes, stops, and schedules. Vehicle and
          <b>Vehicle and Driver Information:</b> Maintain information about vehicles, drivers,
            and their schedules.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">*Late Fee Alerts:</h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Automatically notify parents/students about impending or overdue fee
            payments, ensuring timely payments and adherence to school fee
            policies.
          </li>
        </ul>
        <img src={image3} alt="" className="mx-auto w-2/5" />
        <h2 className="text-xl lg:text-2xl font-semibold">
        Analytics:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Generate all types of reports, starting from administration to the entire parent-student
management setup, which includes inquiry reports, admission stats, financial reports, etc.

          </li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-semibold font-desc">
          We can customize as per the requirements and surely can meet all the
          needs to make the schooling easier from both business and
          parents/students perspective.
        </h3>
      </div>
      <Footer />
    </div>
  );
};

export default SchoolManagement;
