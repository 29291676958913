import React, { useEffect } from "react";
import Navbar from "../NavBar";
import image1 from "../../assets/images/HospitalManagement1.png";
import image2 from "../../assets/images/HospitalManagement2.jpg";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
const HospitalManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar color={"black"} />
      <Helmet>
        <title>Hospital Management Software</title>
        <meta
          name="description"
          content="Optimize hospital operations with our integrated management software."
        />
        <meta
          name="keywords"
          content="hospital management, healthcare software, patient care software"
        />
      </Helmet>
      <div className="container flex justify-center  flex-col gap-4 py-16 px-4 sm:px-6 lg:px-32">
        <div className="heading text-center">
          <h2 className="text-3xl lg:text-4xl font-bold text-Cdark">
            DigIT-Hack Integrated Hospital Management Software
          </h2>
        </div>
        <h2 className="text-2xl font-semibold text-start">Objective:- </h2>
        <h2 className="text-2xl font-semibold text-start">
          To Provide an integrated Solution for the Hospital, which{" "}
        </h2>

        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          • Helps in Efficient Management of the Hospital. <br />
          • Enhance Patient Care <br />
          • Improve work efficiency <br />
          • Improve Fiscal Control <br />
          • Eliminate the chances of any Pilferage <br />
          • Enable the Growth of the Hospital. <br />
        </p>
        <h2 className="text-2xl font-semibold text-start">What you need:-</h2>

        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          • Be fully informed about the Vital Stats of the Hospital. <br />
          • Lesser dependency on middle & Lower Management. Full control on the
          staff. <br />
          • Take corrective actions based on the data instantly. <br />
          • Helps you to Plan any future activity. <br />
          • Ultimate Aim – Better Patient Care with Efficiency. <br />
        </p>
        <img src={image1} alt="" className="mx-auto w-3/4" />
        <h2 className="text-2xl font-semibold text-start">DigIT-Hack Integrated Hospital Management Features-</h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        • DigIT-Hack streamlines and integrate the operation processes and information flow in the hospital to synergize the resources namely men, material, money and equipment’s through information. <br />
• This ERP facilitates hospital-wide Integrated Information System covering all functional areas like out & in Patients Billing & Management, Patient Beds, Visiting Consultants, Medical Stores, Pathology Laboratories, Radiology Laboratories, Imaging, Pharmacy, Manpower Management, Kitchen and Laundry Services etc. <br />
• It performs core hospital activities and increases customer service thereby augmenting the overall Hospital Image. ERP bridges the information gap across the hospital <br />
• DigIT-Hack eliminates the most of the business problems like Material shortages, Productivity enhancements, Customer service, Cash Management, Inventory problems, Quality problems, Prompt delivery, Pilferage, TPA Billing etc. <br />

        </p>
        <h2 className="text-2xl font-semibold text-start">Key Feature:-</h2>

<p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
• DigIT-Hack is a fully-integrated, Hospital Information System Solution. <br />
• DigIT-Hack Software gives a total integration of order entry systems, administrative system, and departmental subsystems within a hospital. <br />
• DigIT-Hack Software allows for scalability, reliability, efficient data processing, quick decision making, reduced maintenance and overheads. <br />
• DigIT-Hack Software data is stored in a single database providing real time data across applications throughout the hospital. Since all the data is stored centrally, it can be viewed simultaneously from multiple terminals giving all departments’ access to timely, up-to-date patient information. <br />
• DigIT-Hack Software offers a fool proof data security without user intervention to archive data. <br />
• DigIT-Hack Software is a comprehensive information system dealing with all aspects of information processing in a hospital. This encompasses human (and paper-based) information processing as well as data processing machines. <br />
• As an area of Medical Informatics the aim of DigIT-Hack Software Plus is to achieve the best possible support of patient care and administration by electronic data processing. <br />

</p>
<h2 className="text-2xl font-semibold text-start">Module:-</h2>

<p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
1. Home & Update (Total OPD, IPD, Appointment, Bed Occupancy, Head Wise Collection Summary, Admin Dash board) <br />
2. Appointments (Direct, Website, Telephone, Online) <br />
3. Pt. Registration (OPD/IPD/ Follow-Ups/ Revisit) <br />
4. Token Screen (Doctor Wise, Vitals Entry,) <br />
5. Doctor Prescription (Multi-Specialty i.e. - General Medicine, Gyneac & Obst, Ophthalmology, Dentist, ENT, Diabetes, Paediatrician, Psychiatric) <br />

** Tele Medicine & Video Conferencing System with payment Gateway Connected.** <br />
6. Day Care & OPD Billing (All Services like- Lab, Doctor Fee, Hospital Service, Radiology etc.) <br />
7. Laboratory (Sampling, Report, Billing, Inventory) <br />
8. Radiology (All Service Head, X-Ray, USG, MRI, CT scan, ECG etc.) <br />
9. Medical Sales (New Patient, OPD & IPD Patient, Corporate Patient Billing, returns, day collection, closing stock, counter wise stock) <br />
10. IPD Files (Admission, Consent, Vitals Charts, Medication, Progress Charts etc.) <br />
11. IPD Dr. Note (RMO/Dr. Advises Treatment, Nursing Follow-Ups) <br />
12. Nursing Station (Equipment, Lab-Investigation, Radiology, Medicine, Dr. Visit, Ward Transferring Blood Transfusion, Tubectomy, Copper-T, Birth & Delivery Entry, Death Entry, Inventory, Diet Planning, Canteen Order, CSSD, Bio Medical Waste ) <br />
14. Operation Theatre (OT Planning & Schedule Manager, Pre-Operative Note, Post-Operative Notes, Images & Video of OT for research purpose) <br />
15. Discharge Summary (ICD -10 with 67 Thousand Diagnoses with Who Compliance) <br />
16. IPD Billing (Corporate Bills, Package, Discount, Advance, Gate Pass, Provisional & Final Bills, Details Bills with Head wise etc.) <br />
17. Diabetes (Tele Medicine System Investigation, & Medicine records, Patient Education, Diet Plan) <br />
18. Vaccination (Vaccine Reminder for Baby Patient) <br />
19. Financial Report (OPD & IPD Collection, Account Summary, Day Collection Hand Over, Corporate Payment Reminder Sheet) <br />
20. Integrated Reports (Head wise, Patient wise, monthly collection, balance sheet, Hospital Data Sheet more then 450+ reports) <br /> 
21. Records (Non-Financial Records required for Govt. & Other purpose like- birth register, death register, PCPNDT data, Admission & Discharge Register etc. 50+) <br />
22. Expenditure (General Expense, Party Purchase, Accounting system for Hospitals) <br />
23. Referral Management (Share & Incentives of Referral, In-house & On Call Doctors) <br />
24. Master (for create, edit, delete all required master data for Hospitals, Doctor, Lab, radiology, TPA Panel, Package, Royalty Card etc.) <br />
25. Settings (Ward, Bed, Room Tariff, Discharge Summary templates) <br />
26. Human Recourse (Hospital Employee Profile, Attendance, Payroll, Salary, Progress chart, Leave Management, Duty Roster) <br />
27. Ambulance (Driver, Ambulance, Patient Booking System, Log-book Manager) <br />
28. SMS (Bulk SMS, Auto SMS Reminder to Patient, Doctor, Staff) <br />
29. Policy/ Complaints (Patient/ Staff internal Complaints related to Hospital service, staff behaviour or any kind of legal issue management) <br />
30. Pharmacy (Purchase, Returns, Inventory, Dept. Supply, GSTIN Statement (Sales & Purchase both) Short Stock, Expiry, Item Master etc.) <br />
31. Inventory (Hospital Laundry, Assets, & Consumable & Non-Consumable Item, Inter Dept. Supply Management, Stock, Request Management, AMC Balance sheet, etc.) <br />
32. Patient Document / Patient EMR (Patient Profiles ,All Bills, Visit Entry, Lab & radiology reports, OT Notes, Discharge Summary, Vitals etc. ) <br />
33. Certificate (Birth, Medical, death Certificate Systems) <br />
34. ICU/Emergency Management (Billing, Patient Records, Quick Treatment Plans) <br />
35. Patient Portal (Online Module for Patient Coronation with Hospitals) <br />
36. Cashless (TPA/HMO/Medical Aid Management of Billing & Payment Reconciliation) <br /> 
37. Blood Bank (Donor Profile, Consent, Testing of Blood, Component creation, discard, NGO profiles, Patient Profile & Blood Issue, Blood requisition, stock report of blood group & availability tracking ) <br /> 
38. Internal SMS (of Hospital staff use only to send files, massages, information related to administration, patient etc.) <br /> 
39. Canteen Management (Diet Issue to patient, sales of outside, billing, stock, Daily Collection of Canteen etc.) <br /> 
40. Bio Medical Waste (Dustbin management, Disposal Process, & Reports Hospital Medical Waste) <br /> 
41. CSSD (Central Sterilize Supply Department) <br /> 


</p>
        <img src={image2} alt="" className="mx-auto w-1/3" />

        <h3 className="text-xl lg:text-2xl font-desc mb-2">
        Ready to Streamline Your Hospital Operations? Transform your hospital's efficiency and patient/visitor satisfaction with our management software. 
<br/>
<span className="font-bold">Contact us to schedule a demo or discuss how our software can optimize your hospital management!</span>
        
        </h3>
      </div>
      <Footer />
    </div>
  );
};

export default HospitalManagement;

// export default
