import React, { useEffect } from "react";
import Navbar from "../NavBar";
import image1 from "../../assets/images/AdvocateOffice.png";
import image2 from "../../assets/images/AdvocateOffice2.png";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
const AdvocateOffice = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar color={"black"} />
      <Helmet>
        <title>Advocate Office</title>
        <meta
          name="description"
          content="Streamline billing and invoicing with our tailored software."
        />
        <meta
          name="keywords"
          content="billing software, invoice management, retail billing"
        />
      </Helmet>
      <div className="container flex justify-center  flex-col gap-4 py-16 px-4 sm:px-6 lg:px-32">
        <div className="heading text-center">
          <h2 className="text-3xl lg:text-4xl font-bold text-Cdark">
            Advocate Office Management System
          </h2>
        </div>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Optimize Your Legal Practice with Our Advocate Office Management
          Software! Welcome to DigIT-Hack, where we empower legal professionals
          with a comprehensive office management solution tailored specifically
          for advocates. Our software is meticulously crafted to streamline case
          management, enhance client communication, and facilitate seamless
          operations within your legal practice.
        </p>
        <img src={image1} alt="" className="mx-auto w-1/2" />
        <h2 className="text-2xl font-semibold text-start">Key Features</h2>
        <h2 className="text-xl lg:text-2xl font-semibold">1. Case Management -</h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Centralized Database: Store and organize case-related information,
          documents, and communications in a secure, centralized database. Task
          Assignment: Allocate tasks, deadlines, and responsibilities to your
          team, ensuring efficient case progression.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          2. Client Relationship Management (CRM) -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Client Information: Maintain detailed client profiles, track
          interactions, and manage communication histories for enhanced client
          relationships. Automated Reminders: Set reminders for important client
          meetings, court appearances, or document submissions.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">3. Document Management -</h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Document Repository: Store, organize, and access case-related
          documents securely, ensuring easy retrieval when needed. Version
          Control: Track document versions and revisions, maintaining accuracy
          and integrity.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">4. Billing and Invoicing -</h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Time Tracking: Capture billable hours accurately, enabling precise
          invoicing for services rendered. Invoice Generation: Create
          professional invoices seamlessly, improving billing efficiency.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          5. Legal Calendar and Deadlines -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Calendar Integration: Sync legal calendars with important deadlines,
          hearings, and appointments. Deadline Alerts: Receive automated
          reminders for upcoming court dates, filings, and important case
          milestones.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">Why Choose Us?</h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Specialized for Advocates: Tailored specifically for the needs of
          legal practitioners, ensuring it meets the unique demands of an
          advocate's office. Security and Compliance: Maintain confidentiality
          and comply with legal standards through secure data management and
          adherence to regulations. Efficiency and Productivity: Enhance
          productivity by streamlining processes, allowing advocates to focus on
          delivering exceptional legal services.
        </p>
        <img src={image2} alt="" className="mx-auto w-2/5"/>
        <h3 className="text-xl lg:text-2xl">
          Ready to Optimize Your Legal Practice? Transform your legal practice with streamlined operations and enhanced
          client service. 
        <br/>
        <span className="font-bold">
          Contact us to schedule a demo or discuss how our
          software can revolutionize your advocate office management!
        </span>
        </h3>
      </div>
      <Footer/>
    </div>
    
  );
};

export default AdvocateOffice;

