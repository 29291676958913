import React, { useEffect } from "react";
import Navbar from "../NavBar";
import image1 from "../../assets/images/DigitalMarketing1.png";
import image2 from "../../assets/images/DigitalMarketing2.jpg";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
const DigitalMarketing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar color={"black"} />
        <Helmet>
          <title>Digital Marketing</title>
          <meta
            name="description"
            content="Empower your business with cutting-edge digital marketing strategies and solutions."
          />
          <meta
            name="keywords"
            content="digital marketing, SEO, social media marketing, content marketing, PPC advertising"
          />
        </Helmet>
      <div className="container flex justify-center  flex-col gap-4 py-16 px-4 sm:px-6 lg:px-32">
        <div className="heading text-center">
          <h2 className="text-3xl lg:text-4xl font-bold text-Cdark">Digital Marketing</h2>
        </div>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Welcome to DigIT-Hack, where we empower businesses to thrive in the
          digital landscape through cutting-edge strategies and tailored
          solutions. Our comprehensive suite of services is designed to elevate
          your online presence, engage your audience, and drive tangible
          results.
        </p>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc mt-3">
          We can make your presence felt by a mass by implementing different
          techniques!
        </p>

        <h2 className="text-2xl font-semibold text-start italic">
          Our Key Techniques –
        </h2>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Search Engine Optimization (SEO):
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Achieve higher visibility and organic traffic with our SEO expertise.
          We employ industry-leading tactics to optimize your website, enhance
          its ranking on search engine results pages, and ensure long-term
          success.
        </p>
        <img src={image1} alt="" className="mx-auto w-3/5" />
        <h2 className="text-xl lg:text-2xl font-semibold">
          Social Media Marketing:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Harness the power of social media platforms to foster meaningful
          connections with your audience. Our strategic approach to social media
          marketing helps you create engaging content, build brand loyalty, and
          drive conversions.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Content Marketing:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Compelling content is at the heart of successful digital campaigns.
          Our content marketing strategies focus on crafting valuable, relevant,
          and shareable content that resonates with your target audience,
          boosting engagement and establishing your brand as an authority in
          your industry.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Pay-Per-Click (PPC) Advertising:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Maximize your ROI with targeted PPC campaigns. Our data-driven
          approach ensures that your ads reach the right audience at the right
          time, driving quality traffic and delivering measurable results.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold italic">
          Why Choose Us?
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc mt-2">
          Tailored Strategies: We understand that every business is unique. Our
          team crafts personalized strategies aligned with your specific goals
          and needs.
        </p>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc mt-2">
          Expertise and Innovation: Stay ahead in the ever-evolving digital
          landscape with our team of seasoned experts who are abreast of the
          latest trends and technologies.
        </p>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc mt-2">
          Transparency and Reporting: We believe in transparent communication.
          Detailed reports and analytics provide insights into campaign
          performance, empowering you to make informed decisions.
        </p>
        <img src={image2} alt="" className="mx-auto w-3/5" />

        <h3 className="text-xl lg:text-2xl font-desc mb-2">
        Ready to Optimize Your Legal Practice?
          Transform your legal practice with streamlined operations and enhanced
          client service.
          <br />
          <span className="font-bold">
          Contact us to schedule a demo or discuss how our software can
          revolutionize your advocate office management!
          </span>
          
        </h3>
      </div>
      <Footer />
    </div>
  );
};

export default DigitalMarketing;
