import React, { useEffect } from "react";
import Card from "./Card";
import Industries from "../assets/data";
import Navbar from "./NavBar";
import Footer from "./Footer";
const Category = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="flex flex-wrap justify-center bg-[#f1f2f7]  bg-no-repeat bg-cover gap-4 relative">
        <Navbar color={"black"} />

        <div className="p-4 w-full flex flex-wrap justify-center gap-x-4 mb-10 lg:px-28 md:p-8">
          <div className="w-full flex flex-col items-center ">
            <h2 className="text-center lg:text-5xl md:text-3xl text-2xl font-bold tracking-tight text-Cdark font-['Kalnia',_serif] sm:text-5xl">
              Our Services
            </h2>
            {/* <span className="w-full h-1 bg-violet-700 rounded-tr-lg rounded-tl-lg  md:mb-5 mb-2"></span> */}
          </div>
          {Industries.map((item) => (
            <Card key={item.id} item={item} />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Category;
