import React, { useEffect } from "react";
import Navbar from "./NavBar";
import aboutBg from "../assets/images/aboutPage.png";
import ImageSlider from "./ImageSlider";
import Footer from "./Footer";
import { BsFillClipboard2DataFill } from "react-icons/bs";
import { HiUserGroup } from "react-icons/hi2";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { useNavigate } from "react-router-dom";
const About = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="">
        <Navbar color={"black"} />

        <section className="bg-gray-100 h-full overflow-x-hidden">
          <div className="container mx-auto lg:py-16 px-4 sm:px-6 lg:px-32">
            <div className="flex lg:flex-row flex-col w-full justify-center items-center lg:gap-8 gap-4">
              <div className="mt-12 md:mt-0 lg:h-[30rem] h-[24rem] w-full flex-col flex justify-center items-center order-2 lg:order-1 lg:mb-0 mb-6">
                <ImageSlider />
                <button
                  className="md:text-lg mt-4 text-sm border-2 border-violet-700 rounded-lg p-2 "
                  onClick={() => {
                    navigate("/category");
                  }}
                >
                  Explore all Products
                </button>
              </div>
              <div className="lg:order-2 w-full">
                <div className="heading">
                  <p className="text-lg">Learn more about us</p>
                  <div className="flex w-full lg:h-44 h-24 gap-2 items-start">
                    <span className="w-5 h-full bg-violet-700 block"></span>
                    <h2 className="lg:text-5xl md:text-3xl text-2xl font-bold">
                      TRUSTED IT{" "}
                      <span className="text-violet-700">SOFTWARE</span> AGENCY
                      FOR ANY BUSINESS NEED
                    </h2>
                  </div>
                </div>
                <p className="mt-4 ">
                  At DigIT Hack, our mission is to empower businesses and
                  industries with cutting-edge technologies, tailored
                  strategies, and exceptional services. We strive to be at the
                  forefront of innovation, providing our clients with the tools
                  they need to thrive in the digital age and achieve
                  unparalleled success.
                </p>
                <h2 className="text-lg mt-5 font-bold">Industry Expertise:</h2>
                <p>
                  Our team comprises experts with deep domain knowledge and
                  years of experience in DigIT Hack. We understand the unique
                  challenges and opportunities in the industry, enabling us to
                  deliver solutions that resonate with our clients' specific
                  needs.
                </p>
                <h2 className="text-lg mt-5 font-bold">
                  Innovation and Technology-Driven Approach:
                </h2>
                <p>
                  We are passionate about technology and continuously explore
                  the latest industry trends and advancements. By combining
                  innovation with practicality, we deliver solutions that push
                  the boundaries of what is possible.
                </p>
                <h2 className="text-lg mt-5 font-bold">
                  Client-Centric Focus:
                </h2>
                <p>
                  Our clients are at the heart of everything we do. We take the
                  time to understand their goals, challenges, and aspirations,
                  allowing us to deliver personalized solutions that exceed
                  expectations.
                </p>
                <h2 className="text-lg mt-5 font-bold">
                  Collaborative Partnerships:
                </h2>
                <p>
                  We believe that the best outcomes arise from collaboration. We
                  work closely with our clients, fostering strong, long-lasting
                  relationships that are built on trust, transparency, and
                  mutual respect. We have diverse products to serve as per the
                  need of different industries. You can check out all of our
                  services in the products/service section.
                </p>
              </div>
            </div>
          </div>
          <div
            className="container my-10 h-52 bg-no-repeat bg-right bg-cover bg-violet-500 flex w-full"
            style={{ backgroundImage: `url('${aboutBg}')` }}
          >
            <div className="p-4 w-full justify-center items-center flex flex-col ">
              <div className="bg-white justify-center items-center flex md:w-20 md:h-20 w-16 h-16 rounded-2xl rotate-45">
                <BsFillClipboard2DataFill className="text-4xl text-violet-500 -rotate-45 " />
              </div>
              <h2 className=" md:text-lg text-sm mt-3 font-bold">
                  Projects 30+
                </h2>
            </div>
            <div className="p-4 w-full justify-center items-center flex flex-col ">
              <div className="bg-white justify-center items-center flex flex-col md:w-20 md:h-20 w-16 h-16 rounded-2xl rotate-45">
                <MdOutlineMiscellaneousServices className="text-4xl text-violet-500 -rotate-45 " />
              </div>
                <h2 className=" md:text-lg text-sm mt-3 font-bold">
                  Services 15+
                </h2>
            </div>
            <div className="p-4 w-full justify-center items-center flex flex-col ">
              <div className="bg-white justify-center items-center flex flex-col md:w-20 md:h-20 w-16 h-16 rounded-2xl rotate-45">
                <HiUserGroup className="text-4xl text-violet-500 -rotate-45" />
              </div>
                <h2 className=" md:text-lg mt-3 text-sm font-bold">
                  Team 7+
                </h2>
            </div>
          </div>
          <div className="container mx-auto lg:py-16 px-4 sm:px-6 lg:px-32 lg:mb-0 mb-5">
            <p className="text-lg">Our Vision & Mission</p>
            <h2 className="lg:text-4xl md:text-3xl font-bold lg:w-4/5 w-full text-xl text-Cdark font-heading">
              WE HAVE THE <span className="text-violet-700">VISION</span> TO
              PROVIDE THE BEST SOFTWARE SERVICE TO OUR CUSTOMERS.
            </h2>
            <p>
              Our vision is to be a catalyst for positive change and progress,
              inspiring individuals and businesses to reach new heights through
              innovative solutions and collaborative efforts. We envision a
              future where technology, creativity, and compassion converge to
              address the world's most significant challenges, making a lasting
              impact on the lives of people and the planet.
              <br></br>
              We envision a future where technology, creativity, and compassion
              converge to address the world's most significant challenges.
              Through our expertise and collaborative approach, we aim to drive
              positive change and make a lasting impact on the lives of people
              and the planet.
            </p>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default About;
