import React, { useEffect } from "react";
import Navbar from "../NavBar";
import image1 from "../../assets/images/TransportManagement1.png";
import image2 from "../../assets/images/TransportManagement2.png";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
const TransportManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar color={"black"} />
      <Helmet>
        <title>Transport Management Software</title>
        <meta
          name="description"
          content="Streamline your transportation operations with our cutting-edge transport management software."
        />
        <meta
          name="keywords"
          content="transport management, logistics software, fleet management, transportation software"
        />
      </Helmet>
      <div className="container flex justify-center  flex-col gap-4 py-16 px-4 sm:px-6 lg:px-32">
        <div className="heading text-center">
          <h2 className="text-3xl lg:text-4xl font-bold text-Cdark">
            Transport management software
          </h2>
        </div>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Streamline Your Transportation Operations with Cutting-Edge
          Transporter Software <br />
          Welcome to DigIT-Hack, where efficiency meets innovation in
          transportation management. Our transporter software is designed to
          revolutionize the way you handle logistics.
        </p>

        <img src={image1} alt="" className="mx-auto w-1/2" />

        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          DigIT-Hack is dedicated to simplifying and optimizing transportation
          processes for businesses across industries. With a keen focus on
          seamless integration, real-time tracking, and intelligent route
          optimization, we empower our clients to take control of their
          logistics operations and drive efficiency at every turn.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          2. Client Relationship Management (CRM) -
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Client Information: Maintain detailed client profiles, track
          interactions, and manage communication histories for enhanced client
          relationships. Automated Reminders: Set reminders for important client
          meetings, court appearances, or document submissions.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">Key Features:</h2>
        <h2 className="text-xl lg:text-2xl font-semibold">Fleet Management:</h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Efficiently manage your entire fleet, from maintenance schedules and
          fuel consumption to driver performance monitoring. Optimize resources
          and enhance operational effectiveness.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Consignment Booking:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Effortlessly manage consignment bookings, scheduling, and tracking,
          ensuring a smooth process from booking to delivery.2.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Account Receivable:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Track and manage account receivables seamlessly within the software,
          streamlining payment processes and ensuring efficient financial
          management.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">Product Delivery:</h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Track product deliveries in real-time, providing precise updates to
          customers and ensuring timely and accurate deliveries
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Entry Invoice Generation:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Automate invoice generation upon delivery completion, reducing manual
          work and ensuring accuracy in billing.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Miscellaneous Expenses Tracking:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Efficiently track miscellaneous expenses related to transportation
          operations, enabling better cost control and financial visibility.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Comprehensive Reporting and Analytics:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Generate detailed reports and analytics to gain insights into key
          performance metrics. Analyze fuel usage, delivery times, and more to
          make informed decisions and drive improvements.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">How It Works:</h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Our transporter software seamlessly integrates into your existing
          systems, offering an intuitive interface for easy adoption. From
          initial setup to ongoing support, we ensure a smooth transition and
          provide comprehensive training for your team.
        </p>
        <img src={image2} alt="" className="mx-auto w-[35%]" />
        <h2 className="text-xl lg:text-2xl font-semibold">
          Industries We Serve:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          DigIT-Hack caters to a wide array of industries, including e-commerce,
          manufacturing, retail, and more. Our customizable solutions adapt to
          the unique needs of each sector, ensuring tailored solutions for
          maximum efficiency.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">Get in Touch:</h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Experience streamlined operations and enhanced customer service with
          our Transport management software. <br />
          <span className="font-bold">Contact us to schedule a demo or
          discuss how our software can optimize your Transport business.</span>
        </p>
   
       
      </div>
      <Footer />
    </div>
  );
};

export default TransportManagement;
