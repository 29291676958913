import React, { useEffect } from "react";
import image from "../../assets/images/CAOffice1.jpg";
import Footer from "../Footer";
import Navbar from "../NavBar";
import { Helmet } from "react-helmet";
const CAOffice = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar color={"black"} />
      <Helmet>
        <title>CA Office Management Software</title>
        <meta
          name="description"
          content="Streamline your office operations with our tailored CA office management software."
        />
        <meta
          name="keywords"
          content="CA office management, accounting software, financial management"
        />
      </Helmet>
      <div className="container flex justify-center  flex-col gap-4 py-16 px-4 sm:px-6 lg:px-32">
        <div className="heading text-center">
          <h2 className="text-3xl lg:text-4xl font-bold text-Cdark">
            DigIT-Hack CA Office Management Software
          </h2>
        </div>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          We are pleased to submit this proposal for the development of a
          comprehensive CA Office Management Software tailored to your specific
          needs. This software will streamline your office operations, improve
          efficiency, and enhance client service.
        </p>
        <img src={image} alt="" className="w-3/4 mx-auto" />
        <h2 className="text-2xl font-semibold text-start">
          II. Software Features:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          DigIT-Hack CA Office Management Software will include the following
          key features:
        </p>
        <ul className="list-disc">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Dashboard: A user-friendly dashboard to provide an overview of your
            office's performance and key metrics.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Firm Info, Bank Info, Partner Info, Attachments: Centralized storage
            for all essential information related to your firm, partners, and
            financial institutions.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Daybook: A powerful daybook module that supports Single Daybook
            entry, Multi Daybook entry, and Bulk Daybook entry for efficient
            financial record-keeping.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            GST Invoice: Generate and manage GST-compliant invoices for your
            clients.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Client Master: Maintain a comprehensive database of client
            information, ensuring easy access to client details and histories.
            [Multiple clients you can add]
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            HR Module: Streamline human resources management, including adding
            employees, tracking attendance, managing salary, recording expenses,
            and maintaining office notes. [Multiple users you can create]
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Important Links: Store and organize links to important online
            resources and tools.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Settings: Configure and customize workflow heads, expense heads, and
            client groups to align the software with your unique requirements.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Reports: Generate various reports, including Group Balance Reports,
            Balance Reports with options for monthly or yearly filtering, and
            Salary Profit & Loss Statements.
          </li>
        </ul>
        <h3 className="text-xl lg:text-xl font-desc">
          Ready to Streamline Your CA Office Operations? Transform your office's
          efficiency and client satisfaction with our management software.
    <br/>
    <span className="font-bold">
    Contact us to schedule a demo or discuss how our software can optimize
          your office management!
    </span>
          
        </h3>
      </div>
      <Footer />
    </div>
  );
};

export default CAOffice;
