import React, { useEffect } from "react";
import Navbar from "../NavBar";
import image1 from "../../assets/images/HotelManagement1.png";
import image2 from "../../assets/images/HotelManagement2.png";
import Footer from "../Footer";
import { Helmet } from "react-helmet";

const JewelleryManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar color={"black"} />
      <Helmet>
        <title>Jewellery Store Management Software</title>
        <meta
          name="description"
          content="Simplify jewellery store operations with our advanced management system."
        />
        <meta
          name="keywords"
          content="jewellery software, store management, retail ERP"
        />
      </Helmet>
      <div className="container flex justify-center  flex-col gap-4 py-16 px-4 sm:px-6 lg:px-32">
        <div className="heading text-center">
          <h2 className="text-3xl lg:text-4xl font-bold text-Cdark">
            Jewellery Store Management Software
          </h2>
        </div>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Optimize Your Jewellery Business with Our Specialized Management
          Software. Welcome to DigIT-Hack, where we redefine jewellery shop
          management with our comprehensive software designed specifically for
          the jewellery industry. Our solution is tailored to streamline
          inventory management, enhance sales processes, and elevate customer
          experiences within your jewellery store.
        </p>
        <img src={image1} alt="" className="mx-auto w-3/4" />
        <h2 className="text-2xl font-semibold text-start">Key Features -</h2>
        <h2 className="text-xl lg:text-2xl font-semibold">
          1. Inventory Management
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Precise Stock Tracking: Manage intricate jewellery inventory with
          precision, tracking details like gemstones, metals, and unique
          designs. Variants and SKUs: Organize items by variants, sizes, and
          unique SKU codes, making inventory management efficient and accurate.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          2. Sales and Customer Relationship Management
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Customer Profiles: Maintain detailed customer profiles, including
          purchase history and preferences, enabling personalized service. -
          Sales Tracking: Monitor sales performance, analyze trends, and track
          high-performing products for informed decision-making.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          3. Point of Sale (POS)
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Efficient Transactions: Simplify sales processes with a user-friendly
          POS system designed specifically for jewellery sales. Customizable
          Invoicing: Generate invoices with ease, including options for custom
          discounts and payment plans.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          4. Repair and Customization Tracking
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Repair Management: Track jewellery sent for repair, schedule repair
          work, and manage communications with customers regarding repair
          status. Custom Orders: Manage custom jewellery orders, from design to
          delivery, ensuring customer specifications are met.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          5. Security and Authentication
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Authentication Protocols: Implement security measures to ensure the
          authenticity of high-value items and protect against fraud. Access
          Controls: Set user permissions and access levels to safeguard
          sensitive information.
        </p>

        <h2 className="text-xl lg:text-2xl font-semibold">Why Choose Us?</h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Industry-Specific Focus: Tailored specifically for jewellery
          businesses, addressing the unique intricacies and challenges of the
          industry.
        </p>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Ease of Use and Adaptability: Intuitive interface and customizable
          features ensure easy adaptation for your unique business needs.
        </p>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Dedicated Support: Receive ongoing support and training to maximize
          the software's potential and your operational efficiency.
        </p>
        <img src={image2} alt="" className="mx-auto w-3/5 " />
        <h3 className="text-xl lg:text-2xl font-desc ">
          Ready to Transform Your Jewellery Store Management? Experience
          streamlined operations and enhanced customer service with our
          management software.<br/>
          <span className="font-bold">
          Contact us to schedule a demo or discuss how our software can optimize
          your jewellery business!
          </span>
        </h3>
      </div>
      <Footer />
    </div>
  );
};

export default JewelleryManagement;
