import React, { useRef, useState } from "react";
// import emailjs from "@emailjs/browser";
import Industries from "../../assets/data";
import { useNavigate } from "react-router-dom";

const Modal = () => {
  const [errors, setErrors] = useState({});
  const form = useRef();
  const navigate = useNavigate();

  let products = Industries.map((industry) =>
    industry.products.map((product) => product)
  );

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   const nameInput = form.current["name"];
  //   const numberInput = form.current["number"];
  //   const productInput = form.current["product"];
  //   const errors = {};

  //   if (!nameInput.value.trim()) {
  //     errors.name = "Name is required";
  //   }

  //   if (!numberInput.value.trim()) {
  //     errors.number = "Phone Number is required";
  //   } else if (!/^\d{10}$/.test(numberInput.value)) {
  //     errors.number = "Invalid phone number format";
  //   }

  //   if (!productInput.value.trim()) {
  //     errors.product = "Product is required";
  //   }

  //   if (Object.keys(errors).length > 0) {
  //     setErrors(errors);
  //     return;
  //   }

  //   emailjs
  //     .sendForm(
  //       "service_4kpsvkr",
  //       "template_tpkoslt",
  //       form.current,
  //       "nQX06j-SlXto14zxz"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         alert("Demo scheduled successfully!");
  //         navigate("/"); // Redirect to home page after submission
  //       },
  //       (error) => {
  //         console.log(error.text);
  //         alert("Error scheduling demo!");
  //       }
  //     );

  //   setErrors({});
  // };
  const sendToWhatsApp = (e) => {
    e.preventDefault();

    // Validate form fields
    const nameInput = form.current["name"];
    const numberInput = form.current["number"];
    const productInput = form.current["product"];
    const otherInput = form.current["other"];
    const errors = {};

    if (!nameInput.value.trim()) {
      errors.name = "Name is required";
    }

    if (!numberInput.value.trim()) {
      errors.number = "Phone Number is required";
    } else if (!/^\d{10}$/.test(numberInput.value)) {
      errors.number = "Invalid phone number format";
    }

    if (!productInput.value.trim()) {
      errors.product = "Product is required";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // Format message for WhatsApp
    const message = `*Schedule a Demo Request*\n\n Name: ${nameInput.value}\n Phone: ${numberInput.value}\n Product: ${productInput.value}\n Others: ${otherInput.value || "N/A"}`;

    // Replace YOUR_WHATSAPP_NUMBER with the target number
    const whatsappURL = `https://wa.me/+917657024042?text=${encodeURIComponent(message)}`;

    // Redirect to WhatsApp
    window.open(whatsappURL, "_blank");

    // Navigate back or show success message
    navigate("/");
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <form
        ref={form}
        onSubmit={sendToWhatsApp}
        className="bg-white p-8 rounded-lg shadow-md w-full max-w-md"
      >
        <h2 className="text-2xl font-bold mb-4">Schedule a Demo</h2>

        <div className="mb-4">
          <label className="block font-medium">Name</label>
          <input
            type="text"
            id="name"
            name="from_name"
            className="w-full p-2 border rounded-md"
          />
          {errors.name && <p className="text-red-500">{errors.name}</p>}
        </div>

        <div className="mb-4">
          <label className="block font-medium">Phone Number</label>
          <input
            type="text"
            id="number"
            maxLength="10"
            name="phone_number"
            className="w-full p-2 border rounded-md"
          />
          {errors.number && <p className="text-red-500">{errors.number}</p>}
        </div>

        <div className="mb-4">
          <label className="block font-medium">Product</label>
          <select id="product" name="product" className="w-full p-2 border rounded-md">
            <option selected>Choose a Product</option>
            {products.flat().map((product) => (
              <option key={product?.name} value={product?.name}>
                {product?.name}
              </option>
            ))}
          </select>
          {errors.product && <p className="text-red-500">{errors.product}</p>}
        </div>

        <div className="mb-4">
          <label className="block font-medium">Others</label>
          <textarea id="other" name="other" className="w-full p-2 border rounded-md" />
        </div>

        <button type="submit" className="bg-violet-500 text-white p-2 w-full rounded-md hover:bg-violet-700">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Modal;
