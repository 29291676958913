import React, { useCallback, useEffect, useState } from "react";
// import { content } from "../assets/data";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { ClientsLogo, CatalogImage } from "../assets/data";
const Catalog = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? CatalogImage.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = useCallback(() => {
    const isLastSlide = currentIndex === CatalogImage.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }, [currentIndex]);

  useEffect(() => {
    // Auto change image every 3 seconds (adjust the interval as needed)
    const intervalId = setInterval(() => {
      nextSlide();
    }, 3000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [currentIndex, nextSlide]);
  return (
    <div className="w-full lg:px-28 lg:py-20 md:p-8 p-4 lg:h-full h-auto">
      <div className="w-full flex flex-col items-start mb-4">
        <h2 className="text-center lg:text-5xl md:text-3xl text-2xl font-bold tracking-tight text-Cdark font-['Kalnia',_serif] sm:text-5xl md:mb-5 mb-2">
          Our Clients
        </h2>
        <span className="w-full h-1 bg-violet-700 rounded-tr-lg rounded-tl-lg md:mb-5"></span>
      </div>
      <div className="flex h-full md:flex-row justify-between md:gap-8 gap-4 mb-4 flex-col">
        <div className="left w-full border-0  border-red-500 flex flex-wrap gap-2 items-center justify-center">
          {ClientsLogo.map((item, index) => (
            <img
              src={item}
              alt="logo"
              key={index}
              className="lg:w-full w-28 h-auto lg:h-24 md:h-20 rounded-sm"
              style={{ maxWidth: "200px" }} // Adjust the maximum width as needed
            />
          ))}
        </div>

        <div className="right  border-0  xl:h-[400px] w-full h-[300px] border-red-500 flex justify-center items-center flex-col ">
          <div className="h-full w-full my-auto">
            <div className="h-full w-full relative group">
              <div
                style={{
                  backgroundImage: `url(${CatalogImage[currentIndex].url})`,
                }}
                className="w-full h-full rounded-2xl bg-center bg-cover duration-500"
              ></div>
              {/* Left Arrow */}
              <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
                <BsChevronCompactLeft onClick={prevSlide} size={30} />
              </div>
              {/* Right Arrow */}
              <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
                <BsChevronCompactRight onClick={nextSlide} size={30} />
              </div>
            </div>
            <h2 className="text-lg mt-2 font-bold text-Cdark text-center">Catalogue Images</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Catalog;
