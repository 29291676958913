import React, { useEffect } from "react";
import Navbar from "../NavBar";
import image1 from "../../assets/images/ERPSoftware1.jpg";
import image2 from "../../assets/images/ERPSoftware2.png";
import Footer from "../Footer";
import { Helmet } from "react-helmet";

const ERPSoftware = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar color={"black"} />
      <Helmet>
        <title>Manufacturing(ERP) Management Software</title>
        <meta
          name="description"
          content="Streamline your manufacturing operations with our comprehensive ERP management software."
        />
        <meta
          name="keywords"
          content="manufacturing software, ERP software, production planning, inventory management"
        />
      </Helmet>
      <div className="container flex justify-center  flex-col gap-4 py-16 px-4 sm:px-6 lg:px-32">
        <div className="heading text-center">
          <h2 className="text-3xl lg:text-4xl font-bold text-Cdark">
            DigIT-Hack Manufacturing(ERP) Management Software
          </h2>
        </div>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          In today's rapidly evolving landscape, industries worldwide are
          embracing advanced software solutions tailored to enhance their
          operations. Whether it's revolutionizing or streamlining manufacturing
          processes, the advent of specialized software has become the
          cornerstone of success. DigIT-Hack covers all of the aspects to manage
          the workflow of the ERP system!
        </p>
        <img src={image1} alt="" className="mx-auto w-3/5" />
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Our Manufacturing ERP (Enterprise Resource Planning) software offers
          comprehensive tools to manage various aspects of manufacturing
          operations. Some key features include:
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          1. Resource Planning:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Managing and optimizing the allocation of resources like raw
          materials, equipment, and labor to maximize efficiency and minimize
          waste.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          2. Inventory Management:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Tracking inventory levels in real-time, managing stock, and ensuring
          seamless supply chain operations.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          3. Production Planning and Scheduling:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Creating production schedules, managing workflows, and optimizing
          processes to meet demand while reducing lead times and costs.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          4. Bill of Materials (BOM):
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Maintaining a detailed list of components and materials needed for
          each product, ensuring accurate production planning and cost
          estimation.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          5. Quality Control and Assurance:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Implementing quality checks at various stages of production to
          maintain product standards and compliance with regulations.
        </p>
        <img src={image2} alt="" className="mx-auto w-3/5" />
        <h2 className="text-xl lg:text-2xl font-semibold">
          6. Supply Chain Management:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Integrating with suppliers, managing procurement processes, and
          optimizing the supply chain for timely and cost-effective deliveries.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          7. Shop Floor Control:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Monitoring and managing activities on the factory floor, tracking work
          orders, and ensuring smooth operations.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          8. Traceability and Lot Tracking:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Tracking raw materials or components through production to finished
          goods, allowing for traceability in case of defects or recalls.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          9. Cost Estimation and Analysis:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Calculating the cost of production, analyzing expenses, and
          identifying areas for cost reduction and efficiency improvement.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          10. Financial Management:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Integrating accounting functions, managing invoices, payments, and
          financial reporting to ensure transparency and accuracy in financial
          operations.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          11. Document Management:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Storing and managing documents related to manufacturing processes,
          including specifications, compliance certificates, and work
          instructions.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          12. Business Intelligence and Analytics:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Generating reports and analytics to gain insights into production
          performance, resource utilization, and areas for improvement.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          13. CRM Integration:
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Integrating customer relationship management to manage customer
          orders, inquiries, and feedback seamlessly.
        </p>

        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          We Can customize as per the requirement. We are pretty sure that we
          can meet all your needs and deliver you our product along with upmost
          quality service.
        </p>

        <h3 className="text-xl lg:text-2xl font-semibold font-desc">
          Contact us to schedule a demo or discuss how our software can optimize
          your school management!
        </h3>
      </div>
      <Footer />
    </div>
  );
};

export default ERPSoftware;
